import https from "./https"
import store from "@/store"

const redeemCodeBundle = {
  getRedeemCodeBundles(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/redeem-code-bundles`, {params})
  },
  getCanAssignRedeemCodeBundles(params = {}) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/redeem-code-bundles/can-assign-index`, {params})
  },
  assignRedeemCodeByBundle(id, params = {}) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/redeem-code-bundles/${id}/assign-redeem-codes`, params)
  },
  getRedeemCodeBundle(id) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/redeem-code-bundles/${id}`)
  },
  updateRedeemCodeBundle(id, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/redeem-code-bundles/${id}`, params)
  },
  deleteRedeemCodeBundle(id) {
    const organization = store.state.general.organization
    return https.delete(`admin/organizations/${organization.id}/redeem-code-bundles/${id}`)
  },
  createRedeemCodeBundle(params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/redeem-code-bundles/`, params)
  },
  uploadImageByBase64(image) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/redeem-code-bundles/images/upload-by-base64`, {image})
  },
  removeImageUrl(id) {
    const organization = store.state.general.organization
    return https.delete(`admin/organizations/${organization.id}/redeem-code-bundles/${id}/remove-image-url`)
  },
  getRedeemCodes (redeemCodeBundleId, params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes`, { params })
  },
  preProcessBatchAssign (redeemCodeBundleId, formData) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes/pre-batch-assign`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  batchAssign (redeemCodeBundleId, formData) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes/batch-assign`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  preProcessImoprtRedeemCode (redeemCodeBundleId, formData) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes/pre-process-import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  imoprtRedeemCode (redeemCodeBundleId, formData) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes/import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  saveRedeemCode (redeemCodeBundleId, params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes`, params)
  },
  deleteRedeemCodes (redeemCodeBundleId, params) {
    const organization = store.state.general.organization
    return https.delete(`admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes`, { params })
  },
  assignRedeemCode(redeemCodeBundleId, redeemCodeId, customerId) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes/assign`
    return https.post(url, {
      redeem_code_id: redeemCodeId,
      customer_id: customerId,
    })
  },
  pushRedeemCode(redeemCodeBundleId, redeemCodeId) {
    const organization = store.state.general.organization;
    const url = `admin/organizations/${organization.id}/redeem-code-bundles/${redeemCodeBundleId}/redeem-codes/push`
    return https.post(url, {
      redeem_code_id: redeemCodeId,
    })
  },
}

export default redeemCodeBundle
